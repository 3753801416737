import React, {useRef, useState} from "react"
import {Form, Button, Card, Alert} from "react-bootstrap"
import {useAuth} from "./AuthContext"
import {Link, useNavigate} from "react-router-dom"
import Container from "react-bootstrap/Container";
import {useDispatch, useSelector} from "react-redux";
// import {addTodoAsync, getTodoAsync} from "../app/todoSlice";
import classes from './Auth.module.css'

const cls = [
  'container', 'd-flex', 'align-items-center', 'justify-content-center',
  // 'bg-white',
  'p-4', 'mt-5', 'mw-100', classes.Auth_background
]

export default function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const {login} = useAuth()
  const {error} = useAuth()
  // const [errorLogin, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [buttonClicked, setButtonClicked] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()



  async function handleSubmit(e) {
    e.preventDefault()

    // setError("")
    setLoading(true)
    await login(emailRef.current.value, passwordRef.current.value)
      .then(() => setLoading(false))
  }


  return (
    <>
      <div className={cls.join(' ')}>
        {/*<Container*/}
        {/*  className="d-flex align-items-center justify-content-center"*/}
        {/*  style={{minHeight: "1vh"}}*/}
        {/*>*/}
        <Card className={classes.Auth_card}>
          <Card.Body className={classes.Auth_card_body}>
            <p
              // className="text-center mb-4"
              className={classes.Auth_title}
            >
              Авторизация
            </p>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email">
                <Form.Label className={classes.Auth_lable}>Логин</Form.Label>
                <Form.Control className={classes.Auth_control} type="email" ref={emailRef} placeholder={'e-mail'} required/>
              </Form.Group>
              <Form.Group id="password">
                <div className={classes.Auth_div_for_password_forgot}>
                  <Form.Label className={classes.Auth_lable}>Пароль</Form.Label>
                  <Link to="/forgot-password" className={classes.Auth_forgot}>Забыли пароль?</Link>
                </div>
                <Form.Control className={classes.Auth_control} type="password" ref={passwordRef} placeholder={'********'} required/>
              </Form.Group>
              <div className={classes.Auth_div_for_button}>
                <button
                  // className={[classes.Auth_button, 'mt-4'].join(' ')}
                  className={classes.Auth_button}
                  disabled={loading}
                  // className="w-100 mt-4"
                  type="submit"
                >
                  Войти
                </button>
              </div>
            </Form>
            <div className="w-100 text-center mt-3">
              {/*<Link to="/forgot-password">Забыли пароль?</Link>*/}
            </div>
            <div
              // className="w-100 text-center mb-4"
              className={[classes.Auth_bottom_note, 'w-100', 'text-center', 'mb-4'].join(' ')}
            >
              Еще нет аккаунта? <Link to="/signup" className={classes.Auth_bottom_note_link}>Регистрация</Link>
            </div>
          </Card.Body>
        </Card>
        {/*</Container>*/}
      </div>
    </>
  )
}
