import React, {useEffect, useRef, useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Stack from "react-bootstrap/Stack";
import Button from 'react-bootstrap/Button';
import Alert from "react-bootstrap/Alert";
import {useDispatch, useSelector} from "react-redux";
import {
  addFormAsync,
  addFormReducer, addGroupAsync, addTaskAsync,
  addTaskReducer, deleteGroupAsync, deleteTaskAsync,
  dragReducer,
  dropReducer, dropTaskAsync, editAsync, editGroupTitleAsync, getTasksAsync,
  showAddFormReducer,
  showAddTaskReducer
} from "./kanbanSlice";
import {showAlert} from "../../auth/authSlice";
import classes from './Kanban.module.css';
// import delGroup from "../../img/delGroup.svg"


const generateUniqeId = () => {
  return Date.now().toString(36) + Math.floor(Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)).toString(36)
}

function Kanban() {


  /*
    const tasksArr = [
      {
        id: 1,
        heading: 'Zagolovok1',
        tasks: ['task1', 'task2', 'task3', 'task4']
      },
      {
        id: 2,
        heading: 'Zagolovok2',
        tasks: ['task11', 'task22', 'task33', 'task44']
      },
      {
        id: 3,
        heading: 'Zagolovok3',
        tasks: ['task111', 'task222', 'task333', 'task444']
      },

    ]*/
  // const [tasks, setTask] = useState(tasksArr)
  // const [tasksArrCurrentDrag, setTasksArrCurrentDrag] = useState([])
  // const [dragging, setDragging] = useState(false)
  // const [draggedItem, setDraggedItem] = useState('')
  // const [dragGroupIdState, setDragGroupId] = useState('')
  // const [dragElemIdState, setDragElemId] = useState('')
  // const [dropGroupIdState, setDropGroupId] = useState('')
  const [taskEditedHeading, setTaskEditedHeading] = useState('')
  const [taskEditedId, setTaskEditedId] = useState('')
  const dispatch = useDispatch()
  const kanbanState = useSelector((state) => state.kanban)
  const tasksKanbanState = kanbanState.tasksArr
  // const tasksArrCurrent = [...kanbanState.tasksArr]
  const dragGroupIdKanbanState = kanbanState.dragGroupIdState
  const dropGroupIdKanbanState = kanbanState.dropGroupIdState
  const dragElemIdKanbanState = kanbanState.dragElemIdState
  const draggedItemState = kanbanState.draggedItem
  const showAddTaskForm = kanbanState.showAddTaskForm
  const showAddTaskFormId = kanbanState.showAddTaskFormId
  const showAddGroupForm = kanbanState.showAddGroupForm
  const taskArrInnerNameState = kanbanState.taskArrInnerName
  const idButtonClicked = kanbanState.idButtonClicked
  const alertState = useSelector((state) => state.auth.user.showAlert)
  const showAddTaskFormRef = useRef([])
  const inputRef = useRef('')
  const inputGroupRef = useRef('')
  const inputGroupTaskRef = useRef('')
  const taskTextRef = useRef([])
  const groupTitleRef = useRef([])
  const authState = useSelector((state) => state.auth)
  const authUID = authState.user.authUID
  const authIdToken = authState.user.authIdToken
  const status = authState.user.loggedIn

  // useEffect(() => {
  //   dispatch(getTasksAsync({authUID, authIdToken}))
  // }, [status])

  useEffect(() => {
    dispatch(getTasksAsync({authUID, authIdToken}))
  }, [])

  const drag = (event) => {
    event.dataTransfer.setData('text', event.target.textContent)

    // let dragGroupId = event.target.getAttribute('idgroup') - 1

    let dragGroupIdNew = event.target.getAttribute('databaseid')
    let dragGroupId = tasksKanbanState.findIndex(item => item.dataBaseId === dragGroupIdNew)
    console.log('111 dragGroupId=', dragGroupId)
    // console.log('111 dragGroupIdINDEX=', dragGroupIdINDEX)

    // setDragGroupId(dragGroupId)
    let dragElemId = event.target.getAttribute('id')
    let taskArrInnerName = event.target.getAttribute('taskarrinnername')
    // setDragElemId(dragElemId)
    let draggedItemPayload = event.target.textContent
    // setDraggedItem(draggedItemPayload)
    let draggingPayload = true
    // setDragging(draggingPayload)


    dispatch(dragReducer({
      dragGroupId, dragElemId, draggedItemPayload, draggingPayload, taskArrInnerName
    }))
  }

  const allowDrop = (event) => {
    event.preventDefault()
  }

  const drop = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const data = event.dataTransfer.getData('text')
    // const data = draggedItemState

    // let dropGroupId = event.target.getAttribute('idgroup') - 1

    let dropGroupIdNew = event.target.getAttribute('databaseid')
    let dropGroupId = tasksKanbanState.findIndex(item => item.dataBaseId === dropGroupIdNew)

    if (dragGroupIdKanbanState !== dropGroupId) {
      // setDropGroupId(dropGroupId)
      // const tasksArrCurrent = tasksKanbanState.slice()
      // const tasksArrCurrent = [...tasksKanbanState]

      // Глубокое копирование массива объектов:
      const tasksArrCurrent = tasksKanbanState.map(element => Object.assign({}, element))
      console.log('tasksArrCurrent', tasksArrCurrent)
      // let newInnerTasksArrDrop = [...tasksArrCurrent[dropGroupId].tasksArrInner, data]

      let newInnerTasksArrDrop = [...tasksArrCurrent[dropGroupId].tasksArrInner, {
        title: data,
        id: dragElemIdKanbanState
      }]
      /*let newInnerTasksArrDrop = [...tasksArrCurrent[dropGroupId].tasksArrInner, {
        title: data,
        id: dragElemIdKanbanState
      }]*/

      console.log('tasksArrCurrent[dropGroupId].tasksArrInner', tasksArrCurrent[dropGroupId].tasksArrInner)
      console.log('newInnerTasksArrDrop', newInnerTasksArrDrop)
      tasksArrCurrent[dropGroupId].tasksArrInner = newInnerTasksArrDrop
      // Object.assign(tasksArrCurrent[dropGroupId], {tasksArrInner: newInnerTasksArrDrop})
      window.setTimeout(() => console.log('UPD-tasksArrCurrent[dropGroupId].tasksArrInner', tasksArrCurrent[dropGroupId].tasksArrInner), 1000)

      let newInnerTasksArrDrag = tasksArrCurrent[dragGroupIdKanbanState].tasksArrInner.filter((item, index) => item.id !== dragElemIdKanbanState)
      tasksArrCurrent[dragGroupIdKanbanState].tasksArrInner = newInnerTasksArrDrag
      // setTask(tasksArrCurrent)
      let draggingPayload = false

      /*
            // локальный редьюсер
            dispatch(dropReducer({
              dropGroupId, tasksArrCurrent, draggingPayload, data
            }))
      */

      const taskToDrop = {
        title: draggedItemState,
        id: dragElemIdKanbanState,
      }
      const databaseIdCurrent = tasksKanbanState[dropGroupId].dataBaseId
      dispatch(dropTaskAsync({
        taskToDrop,
        dropGroupId,
        databaseIdCurrent,
        authUID,
        authIdToken,
        draggingPayload,
        data,
        taskArrInnerName: taskArrInnerNameState,
      }))


      const databaseIdDragged = tasksKanbanState[dragGroupIdKanbanState].dataBaseId

      dispatch(deleteTaskAsync({
        databaseIdCurrent: databaseIdDragged,
        authUID,
        authIdToken,
        dragElemIdKanbanState,
        taskArrInnerNameState,
        dragGroupIdKanbanState,
        draggingPayload
      }))

    }

    // let draggingPayload = false
    // setDragging(false)

    status
      ? dispatch(showAlert(false))
      : dispatch(showAlert(true))
  }

  const showAddTaskFormHandler = (event) => {
    status
      ? dispatch(showAlert(false))
      : dispatch(showAlert(true))

    console.log('showAddTaskFormRef.current=', showAddTaskFormRef.current)

    const addTaskButtonClicked = showAddTaskFormRef.current.find(item => item.id === event.target.getAttribute('id'))

    const idButtonClicked = event.target.getAttribute('idbutton')
    console.log('idButtonClicked=', idButtonClicked)
    const addTaskButtonClickedId = addTaskButtonClicked.id
    // const addTaskButtonClickedId = event.target.getAttribute('idbutton')


    // let addTaskButtonClicked = showAddTaskFormRef.current.find(item => item.idbutton === event.target.getAttribute('idbutton'))
    // const addTaskButtonClickedId = addTaskButtonClicked.idbutton

    console.log('addTaskButtonClicked=', addTaskButtonClicked)
    console.log('addTaskButtonClickedId=', addTaskButtonClickedId)

    if (addTaskButtonClickedId === event.target.getAttribute('id') && window.localStorage.getItem('simpletodoLoggedIn')) {
      dispatch(showAddTaskReducer({addTaskButtonClickedId, idButtonClicked}))
    }

    status
      ? dispatch(showAlert(false))
      : dispatch(showAlert(true))
  }

  const hideAddTaskFormHandler = () => {
    dispatch(showAddTaskReducer({addTaskButtonClickedId: ''}))
  }

  const generateUniqeId = () => {
    return Date.now().toString(36) + Math.floor(Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)).toString(36)
  }

  const inputChangeHandler = (event) => {
    console.log('inputRef=', inputRef.current.value)
    // const tasksArrCurrent = tasksKanbanState.map(element => Object.assign({}, element))[showAddTaskFormId-1].tasksArrInner.push({title: inputRef.current.value, id: generateUniqeId()})
    // tasksArrCurrent.push()
  }

  const addTaskHandler = (event) => {
    console.log('inputRef=', inputRef.current.value)
    let tasksArrCurrent = tasksKanbanState.map(element => Object.assign({}, element))
    // tasksArrCurrent[showAddTaskFormId-1].tasksArrInner.push({title: inputRef.current.value, id: generateUniqeId()})
    const tasksArrIdToAdd = showAddTaskFormId - 1

    // const arrIndexClicked = showAddTaskFormId - 1
    const arrIndexClicked = idButtonClicked

    const databaseIdCurrent = tasksKanbanState[arrIndexClicked].dataBaseId
    const taskToAdd = {
      title: inputRef.current.value,
      id: generateUniqeId()
    }

    let newInnerTasksArr = [...tasksArrCurrent[arrIndexClicked].tasksArrInner, taskToAdd]
    tasksArrCurrent[arrIndexClicked].tasksArrInner = newInnerTasksArr


    // dispatch(addTaskReducer({tasksArrCurrent}))
    dispatch(addTaskAsync({
      taskToAdd,
      tasksArrIdToAdd,
      showAddTaskFormId,
      databaseIdCurrent,
      authUID,
      authIdToken,
      arrIndexClicked
    }))

    status
      ? dispatch(showAlert(false))
      : dispatch(showAlert(true))

  }

  const showHideAddGroupFormHandler = () => {

    status
      ? dispatch(showAlert(false))
      ? dispatch(showAddFormReducer())
      : dispatch(showAlert(true))
      : dispatch(showAlert(true))
  }

  const inputGroupChangeHandler = () => {
    console.log('inputRef=', inputGroupRef.current.value)
    // const tasksArrCurrent = tasksKanbanState.map(element => Object.assign({}, element))[showAddTaskFormId-1].tasksArrInner.push({title: inputRef.current.value, id: generateUniqeId()})
    // tasksArrCurrent.push()
  }

  const inputGroupTaskChangeHandler = () => {
    console.log('inputRef=', inputGroupTaskRef.current.value)
  }

  const addGroupHandler = (event) => {
    console.log('inputRef=', inputGroupRef.current.value)
    let tasksArrCurrent = tasksKanbanState.map(element => Object.assign({}, element))
    const addGroupId = tasksArrCurrent.length + 1
    const groupToAdd = {
      id: addGroupId,
      heading: inputGroupRef.current.value,
      tasksArrInner: [
        // {title: inputGroupTaskRef.current.value, id: generateUniqeId()}
      ]
    }

    /*
        // локальное добавление группы:

        tasksArrCurrent.push(
          {
            id: addGroupId,
            heading: inputGroupRef.current.value,
            tasksArrInner: [
                // {title: inputRef.current.value, id: generateUniqeId()}
              ]
          }
        )
        dispatch(addFormReducer({tasksArrCurrent}))
    */

    // добавление группы на сервер и локально:
    dispatch(addGroupAsync({groupToAdd, authUID, authIdToken}))

    status
      ? dispatch(showAlert(false))
      : dispatch(showAlert(true))


  }

  const deleteTaskHandler = (event) => {
    // let dragGroupId = event.target.getAttribute('idgroup') - 1

    let dragGroupIdIdNew = event.target.getAttribute('databaseid')
    let dragGroupId = tasksKanbanState.findIndex(item => item.dataBaseId === dragGroupIdIdNew)


    const dragElemId = event.target.getAttribute('id')
    const taskArrInnerName = event.target.getAttribute('taskarrinnername')
    const databaseId = tasksKanbanState[dragGroupId].dataBaseId


    tasksKanbanState[dragGroupId].tasksArrInner.length > 1
      ?
      dispatch(deleteTaskAsync({
        authUID,
        authIdToken,
        // ниже dragGroupIdKanbanState, dragElemIdKanbanState, taskArrInnerNameState - названия ключей никак не связаны с одноименными переменными, которые вляются селекторами стейта, такие названия оставлены для соответствующих полей в объекте в редьюсере deleteTaskAsync в kanbanSlice:
        dragElemIdKanbanState: dragElemId,
        dragGroupIdKanbanState: dragGroupId,
        databaseIdCurrent: databaseId,
        taskArrInnerNameState: taskArrInnerName,
        draggingPayload: false,
      }))
      :
      dispatch(deleteGroupAsync({
        authUID,
        authIdToken,
        // ниже dragGroupIdKanbanState, dragElemIdKanbanState, taskArrInnerNameState - названия ключей никак не связаны с одноименными переменными, которые вляются селекторами стейта, такие названия оставлены для соответствующих полей в объекте в редьюсере deleteTaskAsync в kanbanSlice:
        // dragElemIdKanbanState: dragElemId,
        dragGroupIdKanbanState: dragGroupId,
        databaseIdCurrent: databaseId,
        // taskArrInnerNameState: taskArrInnerName,
        // draggingPayload: false,
        // editedGroupHeading: groupTitleRef.current
      }))

    status
      ? dispatch(showAlert(false))
      : dispatch(showAlert(true))


  }

  const editTaskChangeHandler = (event) => {

    // console.log('ON_CHANGE_taskTextRef=', taskTextRef.current)
    console.log('TASKevent.target.value=', event.target.value)
    setTaskEditedHeading(event.target.value)
    setTaskEditedId(event.target.getAttribute('id'))
    let dragGroupIdIdNew = event.target.getAttribute('databaseid')
    let dragGroupId = tasksKanbanState.findIndex(item => item.dataBaseId === dragGroupIdIdNew)
    console.log('dragGroupId=', dragGroupId)
  }

  const editTaskHandler = (event) => {
    // let dragGroupId = event.target.getAttribute('idgroup') - 1

    let dragGroupIdIdNew = event.target.getAttribute('databaseid')
    let dragGroupId = tasksKanbanState.findIndex(item => item.dataBaseId === dragGroupIdIdNew)
    console.log('dragGroupId=', dragGroupId)

    // const dragElemId = event.target.getAttribute('id')
    const dragElemId = taskEditedId
    const taskArrInnerName = event.target.getAttribute('taskarrinnername')
    const databaseId = tasksKanbanState[dragGroupId].dataBaseId
    // const taskTitle = taskTextRef.current.value
    // const editedHeading = taskTextRef.current.find(item => item.id  === event.target.getAttribute('id')).value

    const editedHeading = taskEditedHeading

    console.log('editedHeading=', editedHeading)


    dispatch(editAsync({
      authUID,
      authIdToken,
      // ниже dragGroupIdKanbanState, dragElemIdKanbanState, taskArrInnerNameState - названия ключей никак не связаны с одноименными переменными, которые вляются селекторами стейта, такие названия оставлены для соответствующих полей в объекте в редьюсере deleteTaskAsync в kanbanSlice:
      dragElemIdKanbanState: dragElemId,
      dragGroupIdKanbanState: dragGroupId,
      databaseIdCurrent: databaseId,
      taskArrInnerNameState: taskArrInnerName,
      // draggingPayload: false,
      // editedHeading: taskTextRef.current.value,
      editedHeading,
    }))

  }

  const deleteGroupHandler = (event) => {
    // let dragGroupId = event.target.getAttribute('idgroup') - 1

    let dragGroupIdIdNew = event.target.getAttribute('databaseid')
    let dragGroupId = tasksKanbanState.findIndex(item => item.dataBaseId === dragGroupIdIdNew)

    // const dragElemId = event.target.getAttribute('id')
    // const taskArrInnerName = event.target.getAttribute('taskarrinnername')
    const databaseId = tasksKanbanState[dragGroupId].dataBaseId


    dispatch(deleteGroupAsync({
      authUID,
      authIdToken,
      // ниже dragGroupIdKanbanState, dragElemIdKanbanState, taskArrInnerNameState - названия ключей никак не связаны с одноименными переменными, которые вляются селекторами стейта, такие названия оставлены для соответствующих полей в объекте в редьюсере deleteTaskAsync в kanbanSlice:
      // dragElemIdKanbanState: dragElemId,
      dragGroupIdKanbanState: dragGroupId,
      databaseIdCurrent: databaseId,
      // taskArrInnerNameState: taskArrInnerName,
      // draggingPayload: false,
      // editedGroupHeading: groupTitleRef.current
    }))

    status
      ? dispatch(showAlert(false))
      : dispatch(showAlert(true))

  }

  const editGroupTitleHandler = (event) => {
    // let dragGroupId = event.target.getAttribute('idgroup') - 1

    let dragGroupIdIdNew = event.target.getAttribute('databaseid')
    let dragGroupId = tasksKanbanState.findIndex(item => item.dataBaseId === dragGroupIdIdNew)

    // const dragElemId = event.target.getAttribute('id')
    // const taskArrInnerName = event.target.getAttribute('taskarrinnername')
    const databaseId = tasksKanbanState[dragGroupId].dataBaseId
    // const taskTitle = taskTextRef.current.value

    // const groupTitleRefClicked = groupTitleRef.current.find(item => item.id  === event.target.getAttribute('id'))

    const groupTitleRefClicked = groupTitleRef.current[dragGroupId].value

    console.log('groupTitleRef.current[dragGroupId].value=', groupTitleRef.current[dragGroupId].value)
    console.log('groupTitleRefClicked=', groupTitleRefClicked)


    dispatch(editGroupTitleAsync({
      authUID,
      authIdToken,
      // ниже dragGroupIdKanbanState, dragElemIdKanbanState, taskArrInnerNameState - названия ключей никак не связаны с одноименными переменными, которые вляются селекторами стейта, такие названия оставлены для соответствующих полей в объекте в редьюсере deleteTaskAsync в kanbanSlice:
      // dragElemIdKanbanState: dragElemId,
      dragGroupIdKanbanState: dragGroupId,
      databaseIdCurrent: databaseId,
      // taskArrInnerNameState: taskArrInnerName,
      // draggingPayload: false,
      editedGroupHeading: groupTitleRefClicked
    }))

  }

  const groupTitleChageHandler = (event) => {
    // let dragGroupIdIdNew = event.target.getAttribute('databaseid')
    // let dragGroupId = tasksKanbanState.findIndex(item => item.dataBaseId === dragGroupIdIdNew)
    // console.log('groupTitleRef.current[dragGroupId].value=', groupTitleRef.current[dragGroupId].value)
  }

  return (

    <Container className={classes.container}>
      {/*<div className={classes.main_container_wrapper}>*/}
      {alertState
        ? <Alert className={classes.alert_auth} variant="danger">Создание и редактирование списков доступно после
          регистрации</Alert>
        : null}
      <h1 className={classes.h1}>Kanban Board</h1>
      {/*<Container className={classes.main_wrapper}>*/}
      <Row
        // className="bg-primary text-white ml={2}"
        className={classes.groups_container}
      >
        {
          tasksKanbanState.map((item, id) => {
            return (
              <Col
                // xs={5}
                // sm={5}
                // md={4}
                // lg={3}
                // xl={3}
                // xxl={2}
                className={classes.group}
                // className="mx-1"
                // key={item.id}
                // key={item.dataBaseId + Math.random()}
              >
                {/*<span
                  idgroup={item.id}
                >
                  {item.heading}
                </span>*/}
                <Row className={classes.group_title_buttons}>
                  <Col
                    // xs={8}
                    // sm={7}
                    // md={8}
                    // lg={10}
                    // xl={10}
                    // xxl={9}
                  >
                    <input
                      className={classes.group_title}
                      key={item.id + Date.now()}
                      // key={item.id}
                      // key={generateUniqeId()}
                      id={item.id}
                      idgroup={item.id}
                      databaseid={item.dataBaseId}
                      rows="1"
                      ref={el => groupTitleRef.current[id] = el}
                      defaultValue={item.heading}
                      onChange={groupTitleChageHandler}
                    >
                    </input>
                  </Col>
                  <Col className={classes.group_buttons_wrapper}>
                    <Col className={classes.group_button_wrapper}>
                      <button
                        className={classes.group_button_save}
                        // key={item.id}
                        // variant="secondary"
                        onClick={editGroupTitleHandler}
                        idgroup={item.id}
                        databaseid={item.dataBaseId}
                        // id={task.id}
                        // taskarrinnername={task.taskArrInnerName}
                      >
                        {/*<img src='/img/save_group_png.png'/>*/}
                      </button>
                    </Col>
                    <Col className={classes.group_button_wrapper}>
                      <button
                        className={classes.group_button_del}
                        // key={item.id}
                        // variant="secondary"
                        onClick={deleteGroupHandler}
                        idgroup={item.id}
                        databaseid={item.dataBaseId}
                        // id={task.id}
                        // taskarrinnername={task.taskArrInnerName}
                      >
                        {/*<delGroup />*/}
                        {/*<img src='/img/del_group_png.png'/>*/}
                      </button>
                    </Col>
                  </Col>
                </Row>


                {/*<Row
                  // key={item.dataBaseId}
                  // className="bg-success text-white"
                  className={classes.task_text_buttons}
                  xs={1} md={1} lg={1}
                  onDragOver={allowDrop}
                  onDrop={drop}
                >*/}
                {item.tasksArrInner.map((task, taskId) => {
                  return (
                    <Row
                      // xs={3}
                      // sm={3}
                      // md={3}
                      // lg={3}
                      // xl={3}
                      // xxl={3}
                      // xs={6}
                      // sm={3}
                      // md={3}
                      // lg={3}
                      // xl={3}
                      // xxl={2}
                      // className={classes.group}
                      className={classes.task_text_buttons}
                      // xs={1} md={1} lg={1}
                      onDragOver={allowDrop}
                      onDrop={drop}
                    >
                      <Col
                        // xs={2}
                        // sm={2}
                        // md={2}
                        // lg={2}
                        // xl={2}
                        // xxl={2}
                        key={task.id}
                        idgroup={item.id}
                        databaseid={item.dataBaseId}
                        // idgroup={taskId + 1}
                        id={task.id}
                        taskarrinnername={task.taskArrInnerName}
                        // id={item.id.toString() + taskId.toString()}
                        draggable="true"
                        onDragStart={drag}
                        className={classes.task_text_wrapper}
                      >

                        {/*<Form.Control
                            type="text"
                            // ref={}
                          >
                          {task.title}
                          </Form.Control>*/}
                        <textarea
                          // className="form-control"
                          className={classes.task_text}
                          rows="1"
                          id={task.id}
                          ref={taskTextRef}
                          databaseid={item.dataBaseId}
                          // id={taskId}
                          // ref={el => taskTextRef.current[taskId] = el}
                          defaultValue={task.title}
                          onChange={editTaskChangeHandler}
                          // value={taskTitle}
                        >
                            {/*{task.title}*/}
                          </textarea>
                      </Col>
                      <Col className={classes.task_buttons_wrapper}>
                        <Col className={classes.task_button_wrapper}>
                          <button
                            className={classes.task_button_save}
                            // variant="info"
                            onClick={editTaskHandler}
                            idgroup={item.id}
                            databaseid={item.dataBaseId}
                            // id={task.id}
                            id={taskId}
                            taskarrinnername={task.taskArrInnerName}
                          >
                          </button>
                        </Col>
                        <Col className={classes.task_button_wrapper}>
                          <button
                            className={classes.task_button_del}
                            // variant="danger"
                            onClick={deleteTaskHandler}
                            idgroup={item.id}
                            databaseid={item.dataBaseId}
                            id={task.id}
                            taskarrinnername={task.taskArrInnerName}
                          >
                          </button>
                        </Col>
                      </Col>
                    </Row>
                  )
                })}
                {/*</Row>*/}

                {!showAddTaskForm
                  ?
                  <button
                    className={classes.task_button_add}
                    // key={item.id}
                    id={item.id}
                    // id={id}
                    databaseid={item.dataBaseId}
                    idbutton={id}
                    ref={el => showAddTaskFormRef.current[id] = el}
                    // variant="secondary"
                    onClick={showAddTaskFormHandler}
                  >
                    Добавить
                  </button>
                  : null}
                {Number(showAddTaskFormId) === item.id
                  ?
                  <Row>
                    <Stack
                      className={classes.task_add_wrapper}
                      id={item.id}
                      // gap={1}
                    >
                    <textarea
                      // className="me-auto"
                      className={classes.task_add_text}
                      placeholder="Введите текст..."
                      onChange={inputChangeHandler}
                      ref={inputRef}
                      rows="1"
                    />
                      <Stack direction="horizontal" gap={3}>
                        <button
                          className={classes.task_button_add}
                          // variant="secondary"
                          onClick={addTaskHandler}
                        >
                          Добавить
                        </button>
                        <div className="vr"/>
                        <button
                          className={classes.task_button_cancel}
                          // variant="outline-danger"
                          onClick={hideAddTaskFormHandler}
                        >
                          Закрыть
                        </button>
                      </Stack>
                    </Stack>
                  </Row>
                  : null}
              </Col>
            )
          })
        }
        {/*<Col>*/}
        {/*<Container>*/}
        {!showAddGroupForm
          ?
          <Col className={classes.group}>
            <Row className={classes.task_text_buttons}>
              <button
                className={classes.group_add_button}
                // variant="secondary"
                onClick={showHideAddGroupFormHandler}
              >
                <img
                  // className={classes.Component_image_upper_creator_mobile}
                  src='/img/add_group.svg'
                  // alt='quiz_creator_background'
                  width='18' height='18'/>
                <br/>
                Добавить новый список
              </button>
            </Row>
          </Col>
          : null}
        {showAddGroupForm
          ?
          <Col className={classes.group}>
            <Row className={classes.task_text_buttons}>
              <Stack gap={1}>

                <input
                  // className="me-auto"
                  className={classes.group_add_title}
                  placeholder="Введите заголовок..."
                  onChange={inputGroupChangeHandler}
                  ref={inputGroupRef}
                />

                {/*<Form.Control className="me-auto" placeholder="Введите задачу..." onChange={inputGroupTaskChangeHandler}
                              ref={inputGroupTaskRef}/>*/}

                <Stack direction="horizontal" gap={3}>
                  {/*<Button variant="secondary" onClick={addGroupHandler}>Submit</Button>
                <div className="vr"/>
                <Button variant="outline-danger" onClick={showHideAddGroupFormHandler}>Reset</Button>*/}
                  <button
                    className={classes.task_button_add}
                    // variant="secondary"
                    onClick={addGroupHandler}
                  >
                    Добавить
                  </button>
                  <div className="vr"/>
                  <button
                    className={classes.task_button_cancel}
                    // variant="outline-danger"
                    onClick={showHideAddGroupFormHandler}
                  >
                    Закрыть
                  </button>
                </Stack>

              </Stack>
            </Row>
          </Col>
          : null}
        {/*</Container>*/}
        {/*</Col>*/}
      </Row>

      {/* <Container>
            {!showAddGroupForm
              ?
              <Button variant="secondary"
                      onClick={showHideAddGroupFormHandler}
              >Добавить новый список</Button>
              : null}
            {showAddGroupForm
              ?
              <Stack gap={1}>
                <Form.Control className="me-auto" placeholder="Введите заголовок..." onChange={inputGroupChangeHandler}
                              ref={inputGroupRef}/>
                <Form.Control className="me-auto" placeholder="Введите задачу..." onChange={inputGroupTaskChangeHandler}
                              ref={inputGroupTaskRef}/>
                <Stack direction="horizontal" gap={3}>
                  <Button variant="secondary" onClick={addGroupHandler}>Submit</Button>
                  <div className="vr"/>
                  <Button variant="outline-danger" onClick={showHideAddGroupFormHandler}>Reset</Button>
                </Stack>
              </Stack>
              : null}
          </Container>*/}
      {/*</Container>*/}
      {/*</div>*/}
    </Container>

  )
}

export default Kanban