import React, {useEffect} from 'react';
import Kanban from "./containers/Kanban/Kanban";
import MenuNavBar from "./components/MenuNavBar";
import {AuthProvider} from "./auth/AuthContext";
import {Navigate, Route, Routes} from "react-router";
import {useDispatch} from "react-redux";
import Signup from "./auth/Signup";
import Login from "./auth/Login";
import ForgotPassword from "./auth/ForgotPassword";
import {loggingIn, showAlert} from "./auth/authSlice";
import classes from "./App.module.css";

function App() {
  let loggedIn
  Number(window.localStorage.getItem('simpletodoAuthExpiresIn'))
    ? Number(window.localStorage.getItem('simpletodoAuthExpiresIn')) > Date.now()
    ? loggedIn = true
    : loggedIn = false
    : loggedIn = false

  let authUID
  window.localStorage.getItem('simpletodoAuthUID')
    ? Number(window.localStorage.getItem('simpletodoAuthExpiresIn')) > Date.now()
    ? authUID = window.localStorage.getItem('simpletodoAuthUID')
    : authUID = 'qJZG1PTOLvMPbC0sz2MNHOyt8Be2'
    : authUID = 'qJZG1PTOLvMPbC0sz2MNHOyt8Be2'

  let authIdToken
  window.localStorage.getItem('simpletodoAuthIdToken')
    ? Number(window.localStorage.getItem('simpletodoAuthExpiresIn')) > Date.now()
    ? authIdToken = window.localStorage.getItem('simpletodoAuthIdToken')
    : authIdToken = ''
    : authIdToken = ''

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loggingIn({
      loggedIn: loggedIn,
      authUID: authUID,
      authIdToken: authIdToken,
    }))
  }, [])

  return (
    <>
      <div className={classes.main_background}>
        <MenuNavBar/>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Kanban/>}/>
            {/*<Route path="/auth" element={<Auth/>}/>*/}
            {/*<PrivateRoute path="/update-profile" element={<UpdateProfile/>}/>*/}

            {/*          <Route exact path='/' element={<PrivateRoute/>}>
            <Route exact path='/' element={<TodoMain/>}/>
          </Route>*/}

            <Route path="/signup" element={<Signup/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/forgot-password" element={<ForgotPassword/>}/>
            {/*<Route path="/" exact element={<TodoMain/>}/>*/}
            <Route path="*" element={<Navigate to="/"/>}/>
          </Routes>
        </AuthProvider>
      </div>
    </>
  )
}


export default App;
