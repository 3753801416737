import React from "react";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

const generateUniqeId = () => {
  return Date.now().toString(36) + Math.floor(Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)).toString(36)
}

export const addGroupAsync = createAsyncThunk(
  'kanban/addGroupAsync',
  async (payload) => {
    try {
      // const id = generateUniqeId()
      const response = await axios.post(`https://kanban-dcdf1-default-rtdb.asia-southeast1.firebasedatabase.app/groups/${payload.authUID}.json?auth=${payload.authIdToken}`, {
        id: payload.groupToAdd.id,
        heading: payload.groupToAdd.heading,
        tasksArrInner: payload.groupToAdd.tasksArrInner,
      })

      /*await axios.post(`https://todo-f1486-default-rtdb.asia-southeast1.firebasedatabase.app/todos/${quizAuthUID}.json?auth=${quizAuthIdToken}`, this.state.quiz)*/

      console.log('Отправлено на сервер= ', response.data.name)
      console.log('payload= ', payload)
      const group = {
        id: payload.groupToAdd.id,
        heading: payload.groupToAdd.heading,
        tasksArrInner: payload.groupToAdd.tasksArrInner,
        // tasksArr: payload.tasksArrCurrent,
        dataBaseId: response.data.name,
      }
      return {group}
    } catch (error) {
      console.log(error)
    }
  }
)

export const addTaskAsync = createAsyncThunk(
  'kanban/addTaskAsync',
  async (payload) => {
    try {
      // const id = generateUniqeId()
      const response = await axios.post(`https://kanban-dcdf1-default-rtdb.asia-southeast1.firebasedatabase.app/groups/${payload.authUID}/${payload.databaseIdCurrent}/tasksArrInner/.json?auth=${payload.authIdToken}`,
        payload.taskToAdd
        // id: payload.taskToAdd.id,
        // title: payload.taskToAdd.title,
      )

      /*await axios.post(`https://todo-f1486-default-rtdb.asia-southeast1.firebasedatabase.app/todos/${quizAuthUID}.json?auth=${quizAuthIdToken}`, this.state.quiz)*/

      console.log('Отправлено на сервер= ', response.data.name)
      console.log('payload= ', payload)
      const task = {
        id: payload.taskToAdd.id,
        taskToAdd: payload.taskToAdd,
        databaseIdCurrent: payload.databaseIdCurrent,
        showAddTaskFormId: payload.showAddTaskFormId,
        arrIndexClicked: payload.arrIndexClicked,
        taskArrInnerName: response.data.name,
        // dataBaseId: response.data.name,
      }
      return {task}
    } catch (error) {
      console.log(error)
    }
  }
)

export const dropTaskAsync = createAsyncThunk(
  'kanban/dropTaskAsync',
  async (payload) => {
    try {
      // const id = generateUniqeId()
      const response = await axios.post(`https://kanban-dcdf1-default-rtdb.asia-southeast1.firebasedatabase.app/groups/${payload.authUID}/${payload.databaseIdCurrent}/tasksArrInner/.json?auth=${payload.authIdToken}`,
        payload.taskToDrop
        // id: payload.taskToAdd.id,
        // title: payload.taskToAdd.title,
      )

      console.log('Отправлено на сервер= ', response.data.name)
      console.log('payload= ', payload)
      const task = {
        id: payload.taskToDrop.id,
        taskToDrop: payload.taskToDrop,
        dropGroupId: payload.dropGroupId,
        databaseIdCurrent: payload.databaseIdCurrent,
        taskArrInnerName: response.data.name,
        // taskArrInnerName: payload.taskArrInnerName,
        // showAddTaskFormId: payload.showAddTaskFormId
        // dataBaseId: response.data.name,
      }
      return {task}
    } catch (error) {
      console.log(error)
    }
  }
)

export const deleteTaskAsync = createAsyncThunk(
  'kanban/deleteTaskAsync',
  async (payload) => {
    try {
      const response = await axios.delete(`https://kanban-dcdf1-default-rtdb.asia-southeast1.firebasedatabase.app/groups/${payload.authUID}/${payload.databaseIdCurrent}/tasksArrInner/${payload.taskArrInnerNameState}/.json?auth=${payload.authIdToken}`)
      console.log('Удалена строка= ', response)
      const deletedTask = {
        id: payload.dragElemIdKanbanState,
        dragGroupId: payload.dragGroupIdKanbanState,
        draggingPayload: payload.draggingPayload
      }
      console.log('deletedTask= ', deletedTask)
      return {deletedTask}
    } catch (error) {
      console.log(error)
    }
  }
)

export const editAsync = createAsyncThunk(
  'kanban/editAsync',
  async (payload) => {
    try {
      const response = await axios.patch(`https://kanban-dcdf1-default-rtdb.asia-southeast1.firebasedatabase.app/groups/${payload.authUID}/${payload.databaseIdCurrent}/tasksArrInner/${payload.taskArrInnerNameState}/.json?auth=${payload.authIdToken}`, {
        id: payload.dragElemIdKanbanState,
        title: payload.editedHeading,
      })
      console.log('Отредактирована строка= ', response)
      const editedTask = {
        id: payload.dragElemIdKanbanState,
        dragGroupId: payload.dragGroupIdKanbanState,
        // draggingPayload: payload.draggingPayload,
        title: response.data.title,
      }
      console.log('editedTask= ', editedTask)
      return {editedTask}
    } catch (error) {
      console.log(error)
    }
  }
)

export const deleteGroupAsync = createAsyncThunk(
  'kanban/deleteGroupHandler',
  async (payload) => {
    try {
      const response = await axios.delete(`https://kanban-dcdf1-default-rtdb.asia-southeast1.firebasedatabase.app/groups/${payload.authUID}/${payload.databaseIdCurrent}/.json?auth=${payload.authIdToken}`)
      console.log('Удалена группа= ', response)
      const deletedGroupPayload = {
        // heading: payload.editedGroupHeading,
        dragGroupId: payload.dragGroupIdKanbanState,
        databaseIdCurrent: payload.databaseIdCurrent,
        // id: payload.dragElemIdKanbanState,
        // draggingPayload: payload.draggingPayload,
        // title: response.data.title,
      }
      console.log('deletedGroupPayload= ', deletedGroupPayload)
      return {deletedGroupPayload}
    } catch (error) {
      console.log(error)
    }
  }
)

export const editGroupTitleAsync = createAsyncThunk(
  'kanban/editGroupTitleAsync',
  async (payload) => {
    try {
      const response = await axios.patch(`https://kanban-dcdf1-default-rtdb.asia-southeast1.firebasedatabase.app/groups/${payload.authUID}/${payload.databaseIdCurrent}/.json?auth=${payload.authIdToken}`, {
        heading: payload.editedGroupHeading,
      })
      console.log('Отредактирован заголовок группы= ', response)
      const editedGroupHeadingPayload = {
        heading: payload.editedGroupHeading,
        // id: payload.dragElemIdKanbanState,
        dragGroupId: payload.dragGroupIdKanbanState,
        // // draggingPayload: payload.draggingPayload,
        // title: response.data.title,
      }
      console.log('editedGroupHeading= ', editedGroupHeadingPayload)
      return {editedGroupHeadingPayload}
    } catch (error) {
      console.log(error)
    }
  }
)

export const getTasksAsync = createAsyncThunk(
  'todos/getTasksAsync',
  async (payload) => {
    try {
      const response = await axios.get(`https://kanban-dcdf1-default-rtdb.asia-southeast1.firebasedatabase.app/groups/${payload.authUID}.json?auth=${payload.authIdToken}`)

      console.log('Получено с сервера response= ', response)
      console.log('Получено с сервера response.data= ', response.data)
      let tasksArrFromServer
      const dataBaseIds = Object.keys(response.data || {})
      tasksArrFromServer = Object.values(response.data || {})
        .map((task, index) => {
          return (
            {
              id: task.id,
              heading: task.heading,
              tasksArrInner: Object.values(task.tasksArrInner || {}).map((taskInner, taskInnerIndex) => {
                return (
                  {
                    id: taskInner.id,
                    title: taskInner.title,
                    taskArrInnerName: Object.keys(task.tasksArrInner)[taskInnerIndex],
                  }
                )
              }),
              dataBaseId: dataBaseIds[index]
            }
          )
        })
      window.setTimeout(() => console.log('tasksArrFromServer=', tasksArrFromServer), 150)
      // console.log('dataBaseIds=', dataBaseIds)
      return {tasksArrFromServer}
    } catch (error) {
      console.log(error)
    }
  }
)

export const kanbanSlice = createSlice({
  name: 'kanban',
  initialState: {
    tasksArr: [],
    /*tasksArr: [
      {
        id: 1,
        heading: 'Zagolovok1',
        tasksArrInner: [
          {title: 'task1', id: generateUniqeId()},
          {title: 'task2', id: generateUniqeId()},
          {title: 'task3', id: generateUniqeId()},
          {title: 'task4', id: generateUniqeId()},
        ],
        dataBaseId: '',
      },
      {
        id: 2,
        heading: 'Zagolovok2',
        tasksArrInner: [
          {title: 'task11', id: generateUniqeId()},
          {title: 'task22', id: generateUniqeId()},
          {title: 'task33', id: generateUniqeId()},
          {title: 'task44', id: generateUniqeId()},
        ],
        dataBaseId: '',
        // tasksArrInner: ['task11', 'task22', 'task33', 'task44']
      },
      {
        id: 3,
        heading: 'Zagolovok3',
        tasksArrInner: [
          {title: 'task111', id: generateUniqeId()},
          {title: 'task222', id: generateUniqeId()},
          {title: 'task333', id: generateUniqeId()},
          {title: 'task444', id: generateUniqeId()},
        ],
        dataBaseId: '',
        // tasksArrInner: ['task111', 'task222', 'task333', 'task444']
      },
    ],*/
    dragging: false,
    draggedItem: '',
    dragGroupIdState: '',
    dragElemIdState: '',
    dropGroupIdState: '',
    droppedData: '',
    showAddTaskForm: false,
    showAddTaskFormId: '',
    showAddGroupForm: false,
    taskArrInnerName: '',
    idButtonClicked: '',
  },
  reducers: {
    dragReducer: (state, action) => {
      state.dragGroupIdState = action.payload.dragGroupId
      state.dragElemIdState = action.payload.dragElemId
      state.draggedItem = action.payload.draggedItemPayload
      state.dragging = action.payload.draggingPayload
      state.taskArrInnerName = action.payload.taskArrInnerName
    },
    /*
    // локальный drop редьюсер
    dropReducer: (state, action) => {
      // state.dropGroupIdState = action.payload.dropGroupId
      state.tasksArr = action.payload.tasksArrCurrent
      state.dragging = action.payload.draggingPayload
      state.draggedItem = ''
      state.dragGroupIdState = ''
      state.dragElemIdState = ''
      state.droppedData = action.payload.data
    },
    */
    showAddTaskReducer: (state, action) => {
      state.showAddTaskForm = !state.showAddTaskForm
      state.showAddTaskFormId = action.payload.addTaskButtonClickedId
      state.idButtonClicked = action.payload.idButtonClicked
    },
    /*
    // локальный addTask редьюсер
    addTaskReducer: (state, action) => {
      state.tasksArr = action.payload.tasksArrCurrent
    },
    */
    showAddFormReducer: (state, action) => {
      state.showAddGroupForm = !state.showAddGroupForm
    },
    /*
    // локальный редьюсер для добавления группы:
    addFormReducer: (state, action) => {
      state.tasksArr = action.payload.tasksArrCurrent
    },
    */
  },
  extraReducers: (builder) => {
    builder
      .addCase(addGroupAsync.fulfilled, (state, action) => {
        state.tasksArr.push(action.payload.group)
      })
      .addCase(addTaskAsync.fulfilled, (state, action) => {
        // state.tasksArr[action.payload.task.showAddTaskFormId - 1].tasksArrInner.push({...action.payload.task.taskToAdd, taskArrInnerName: action.payload.task.taskArrInnerName})
        state.tasksArr[action.payload.task.arrIndexClicked].tasksArrInner.push({...action.payload.task.taskToAdd, taskArrInnerName: action.payload.task.taskArrInnerName})
      })
      .addCase(dropTaskAsync.fulfilled, (state, action) => {
        state.tasksArr[action.payload.task.dropGroupId].tasksArrInner.push({...action.payload.task.taskToDrop, taskArrInnerName: action.payload.task.taskArrInnerName})

        //старая версия без таск арр иннер нейм
        // state.tasksArr[action.payload.task.dropGroupId].tasksArrInner.push(action.payload.task.taskToDrop)
        state.dragging = action.payload.draggingPayload
        state.draggedItem = ''
        state.dragGroupIdState = ''
        state.dragElemIdState = ''
        state.droppedData = action.payload.data
      })
      .addCase(deleteTaskAsync.fulfilled, (state, action) => {
        const newDraggedarr = state.tasksArr[action.payload.deletedTask.dragGroupId].tasksArrInner.filter(taskInner => taskInner.id !== action.payload.deletedTask.id)
        state.tasksArr[action.payload.deletedTask.dragGroupId].tasksArrInner = newDraggedarr
        state.dragging = action.payload.deletedTask.draggingPayload

        // state.tasksArr[action.payload.deletedTask.dragGroupId].tasksArrInner.filter(taskInner => taskInner.id !== action.payload.deletedTask.id)
        // const newArr = state.tasksArr[action.payload.deletedTask.dragGroupId].tasksArrInner.concat().filter(task => task.tasksArrInner.filter(taskInner => taskInner.id !== action.payload.deletedTask.id))
        // state.tasksArr[action.payload.deletedTask.dragGroupId].tasksArrInner = newArr
      })
      .addCase(editAsync.fulfilled, (state, action) => {
        // const editedTask =
          state.tasksArr[action.payload.editedTask.dragGroupId].tasksArrInner.find(taskInner => taskInner.id === action.payload.editedTask.id).title = action.payload.editedTask.title
        // state.tasksArr[action.payload.editedTask.dragGroupId].tasksArrInner = newDraggedarr
        // state.dragging = action.payload.editedTask.draggingPayload
      })
      .addCase(editGroupTitleAsync.fulfilled, (state, action) => {
        state.tasksArr[action.payload.editedGroupHeadingPayload.dragGroupId].heading = action.payload.editedGroupHeadingPayload.heading
      })
      .addCase(deleteGroupAsync.fulfilled, (state, action) => {
        const newTasksArr =  state.tasksArr.filter(item => item.dataBaseId !== action.payload.deletedGroupPayload.databaseIdCurrent)
        state.tasksArr = newTasksArr
          .map((elem, index) => {
            return (
              {
                ...elem,
                id: index + 1
              }
            )
          }
      )
      })
      .addCase(getTasksAsync.fulfilled, (state, action) => {
        state.tasksArr = action.payload.tasksArrFromServer
      })
  }
})

export const {dragReducer, dropReducer, showAddTaskReducer, addTaskReducer, showAddFormReducer, addFormReducer} = kanbanSlice.actions

export default kanbanSlice.reducer