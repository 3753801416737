import React from "react"
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: {
      loggedIn: false,
      authUID: '',
      authIdToken: '',
      authEmail: '',
      authExpiresIn: '',
      showAlert: false,
    }
  },
  reducers: {
    loggingIn: (state, action) => {
      const user = action.payload
      state.user = user
    },
    showAlert: (state, action) => {
      state.user.showAlert = action.payload
    },
  },
})

export const {loggingIn, showAlert} = authSlice.actions

export default authSlice.reducer