import { configureStore } from '@reduxjs/toolkit';
import kanbanReducer from "../containers/Kanban/kanbanSlice";
import authReducer from "../auth/authSlice";

export const store = configureStore({
  reducer: {
    kanban: kanbanReducer,
    auth: authReducer,
  },
});
