import React, {useRef, useState} from "react"
import {Form, Button, Card, Alert} from "react-bootstrap"
import {useAuth} from "./AuthContext"
import {Link, useNavigate} from "react-router-dom"
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import classes from './Auth.module.css';

export default function Logout() {
  // const emailRef = useRef()
  // const passwordRef = useRef()
  const {logout} = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      await logout()
      navigate("/")
    } catch {
      setError("Failed to log out")
      const errorCode = error.code
      const errorMessage = error.message
      console.log("Failed to log out")
      console.log("errorCode", errorCode)
      console.log("errorMessage", errorMessage)
    }

    setLoading(false)
  }

  return (
    <Navbar.Text
      className={['justify-content-end', classes.Auth_logout].join(' ')}
      onClick={handleSubmit}
      // style={{cursor: 'pointer'}}
    >
      Выход
      {/*<Button onClick={handleSubmit}>Выход*/}
      <svg style={{width: '18', height: '18', marginLeft: '10', marginBottom: '3'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96C43 32 0 75 0 128V384c0 53 43 96 96 96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H96c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32h64zM504.5 273.4c4.8-4.5 7.5-10.8 7.5-17.4s-2.7-12.9-7.5-17.4l-144-136c-7-6.6-17.2-8.4-26-4.6s-14.5 12.5-14.5 22v72H192c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32H320v72c0 9.6 5.7 18.2 14.5 22s19 2 26-4.6l144-136z"/></svg>
      {/*</Button>*/}
    </Navbar.Text>
  )
}
