import React, {useRef, useState} from "react"
import {Form, Button, Card, Alert} from "react-bootstrap"
import {useAuth} from "./AuthContext"
import {Link} from "react-router-dom"
import classes from "./Auth.module.css"

const cls = [
  'container', 'd-flex', 'align-items-center', 'justify-content-center',
  // 'bg-white',
  'p-4', 'mt-5', 'mw-100', classes.Auth_background
]

export default function ForgotPassword() {
  const emailRef = useRef()
  const {resetPassword} = useAuth()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setMessage("")
      setError("")
      setLoading(true)
      await resetPassword(emailRef.current.value)
      setMessage("Check your inbox for further instructions")
    } catch {
      setError("Failed to reset password")
    }

    setLoading(false)
  }

  return (
    <>
      <div className={cls.join(' ')}>
        <Card className={classes.Auth_card}>
          <Card.Body className={classes.Auth_card_body}>
            <p
              // className="text-center mb-4"
              className={classes.Auth_title}
            >
              Восстановление пароля
            </p>
            {error && <Alert variant="danger">{error}</Alert>}
            {message && <Alert variant="success">{message}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email">
                <Form.Label className={classes.Auth_lable}>Введите указанный при регистрации e-mail</Form.Label>
                <Form.Control className={classes.Auth_control}  type="email" ref={emailRef} placeholder={'e-mail'} required/>
              </Form.Group>
              <div className={classes.Auth_div_for_button}>
                <button
                  disabled={loading}
                  // className={[classes.Auth_button, 'mt-4'].join(' ')}
                  className={classes.Auth_button}
                  type="submit">
                  Отправить
                </button>
              </div>
            </Form>
            <div className="w-100 text-center mt-3">
              <Link to="/login" className={classes.Auth_bottom_note_link}>Вход</Link>
            </div>
            <div className={[classes.Auth_bottom_note, 'w-100', 'text-center', 'mb-4'].join(' ')}>
              Еще нет аккаунта? <Link to="/signup" className={classes.Auth_bottom_note_link}>Регистрация</Link>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  )
}
