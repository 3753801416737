// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBTimSQNCva58kPtit--HCn09ysloJqxVE",
  authDomain: "kanban-dcdf1.firebaseapp.com",
  projectId: "kanban-dcdf1",
  storageBucket: "kanban-dcdf1.appspot.com",
  messagingSenderId: "14899818648",
  appId: "1:14899818648:web:20e4cb77e73f3c8d9ea0ed"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth()

export default app